const SERVICES_STATUS = {
	ACTIVE: 1,
	INACTIVE: 0,
	IN_PROCESS: 2,
	REJECTED: 3,
	ACCEPT: 4,
	FINISHED: 5,
	QUOTE_SENDED: 6,
	INIT: 7,
	CANCELLED: 8,
	PENDING_ASSIGNING: 9,
}

export default {
	USER: {
		LEVEL: {
			ADMIN: 1,
			OWNER: 2,
			RENTER: 3,
			PROPERTY_ADMIN: 4,
			CONTRACTOR: 5,
			TECH: 6,
		},
		STATUS: {
			ACTIVE: 1,
			INACTIVE: 0,
			DISPONIBLE: 1,
			SERVICE_ON: 2,
			DISABLED: 3
		},
		VERIFIED: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
	},
	SERVICES: {
		TYPE: {
			CHECK: 1,
			REPAIR: 2,
			MAINTENANCE: 3,
			INSTALLATION: 4,
			EMERGENCY: 5
		},
		STATUS: {
			PENDING: {
				ID: SERVICES_STATUS.ACCEPT,
				LABEL: 'Pendiente',
			},
			INITIATED: {
				ID: SERVICES_STATUS.IN_PROCESS,
				LABEL: 'Activada',
			},
			FINISHED: {
				ID: SERVICES_STATUS.FINISHED,
				LABEL: 'Finalizado',
			},
			DENIED: {
				ID: SERVICES_STATUS.REJECTED,
				LABEL: 'Negado',
			},
			INIT: {
				ID: SERVICES_STATUS.INIT,
				LABEL: 'Iniciado',
			},
			QUOTE_SENDED: {
				ID: SERVICES_STATUS.QUOTE_SENDED,
				LABEL: 'Activada',
			},
			ACTIVE: {
				ID: SERVICES_STATUS.ACTIVE,
				LABEL: 'Activada',
			},
			CANCELLED: {
				ID: SERVICES_STATUS.CANCELLED,
				LABEL: 'Cancelada',
			},
			PENDING_ASSIGNING: {
				ID: SERVICES_STATUS.PENDING_ASSIGNING,
				LABEL: 'Pendiente por asignar',
			},
		}
	},
	PROPERTY: {
		TYPE: {
			HOUSE: {
				ID: 1,
				LABEL: 'Casa',
			},
			DEPARTMENT: {
				ID: 2,
				LABEL: 'Departamento',
			},
			LOCAL: {
				ID: 3,
				LABEL: 'Local',
			},
		},
	},
	BANK: {
		ACCOUNT_TYPE: {
			CURRENT: 2,
			SAVINGS: 1,
		},
	},
	SCHEDULED_TYPES: {
		ANUAL_MAINTENANCE: 1,
		SCHEDULED_MAINTENANCE: 2,
		SCHEDULED_VISIT: 3
	},
	CALL_TYPE: {
		URGENT: 1,
		SCHEDULE: 2
	},
}