import React, { HTMLInputTypeAttribute } from 'react';

interface InputProps {
  className?: string
  filterStyle?: boolean
  noMargin?: boolean
  label?: string
  placeholder?: string | undefined | null
  type?: HTMLInputTypeAttribute
  value?: string | number
  onChange: (value: string) => void
  leftIcon?: string
  preppend?: JSX.Element
  rightIcon?: string
  append?: JSX.Element
}

const Input = (props: InputProps) => {
  
  const filterStyle = props?.filterStyle ? ' filter':'';
  const noMargin = props?.noMargin ? ' no-margin':'';

  return (
    <div className="form--control">
      {props?.label && (
        <div className="input--label">{ props?.label }</div>
      )}

      <div className={`form--input${filterStyle}${noMargin}`}>
        {props?.leftIcon && (
          <img className="input--left-img" src={props.leftIcon} alt="Left Icon"/>
        )}

        {props?.preppend && (
          <div className="input--left-img">{props.preppend}</div>
        )}

        <input
          className={props?.className}
          type={props?.type}
          placeholder={props?.placeholder || ''}
          value={props.value}
          onChange={v => props.onChange(v.target.value)}
        />

        {props?.append && (
          <div className="input--right-img">{props.append}</div>
        )}

        {props?.rightIcon && (
          <img className="input--right-img" src={props.rightIcon} alt="Right Icon"/>
        )}
      </div>

    </div>
  )
}

export default Input;