const INITIAL_STATE = {
	lang: 'en'
}

const language = (state = INITIAL_STATE, action: any) => {
	switch(action.type) {
		case 'SET_LANGUAGE':
			return action.payload;

		default: 
			return state;
	}
}

export default language;