const user = (state = {}, action: any) => {
	switch(action.type) {
		case 'SET_USER':
			return action.payload;

		case 'LOGOUT':
			return {};

		default: 
			return state;
	}
}

export default user;