/* eslint-disable @typescript-eslint/no-use-before-define */
import HoldOn from 'react-hold-on';
import Swal from 'sweetalert2';
import moment from 'moment';
import $ from 'jquery';
import { Constants } from 'utils';
import i18n from 'i18next';

class Globals {

	setLoading = () => {
		HoldOn.open({
			theme: "sk-bounce"
		});
	}

	quitLoading = () => {
		HoldOn.close();
	}

	formatMiles = (n: any, decimals: boolean = true, currency: string = '') => {
		var c: any = isNaN(c = Math.abs(c)) ? 2 : c,
			d: any = d === undefined ? "," : d,
			t: any = t === undefined ? "." : t,
			s: any = n < 0 ? "-" : "",
			i: any = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
			j: any = (j = i.length) > 3 ? j % 3 : 0;

		return currency + ' ' + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	}

	hideTooltip = () => {
		$('button').blur();
	}

	showSuccess = (message: string) => {
		return Swal.fire({
			title: "",
			text: message,
			type: "success",
			showCancelButton: false,
			confirmButtonColor: '#df0611',
		});
	}

	showError = (message?: string | null | undefined) => {
		return Swal.fire({
			title: "",
			text: message ? message : "Se ha producido un error",
			type: "error",
			showCancelButton: false,
			confirmButtonColor: '#df0611',
		});
	}

	confirm = (message: string, callback: any) => {
		this.hideTooltip();
		Swal.fire({
			title: "",
			text: message,
			type: "warning",
			showCancelButton: true,
			confirmButtonText: 'Aceptar',
			cancelButtonText: 'Cancelar',
			confirmButtonColor: '#df0611',
		}).then((confirm: any) => {
			if (confirm.value) {
				callback();
			}
		});
	}

	getDate = (date: string, to: string = 'DD/MM/YYYY', from: string = 'YYYY-MM-DD HH:mm:ss') => {
		return moment(date, from).format(to);
	}

	capitalize = (text: string) => {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	pad(num: number, size: number = 4) {
		var s = num + "";
		while (s.length < size) s = "0" + s;
		return s;
	}

	isPdf(file: string) {
		return file.substr(file.lastIndexOf('.') + 1) === 'pdf';
	}

	getBankAccountType(type: number) {
		switch (type) {
			case Constants.BANK.ACCOUNT_TYPE.SAVINGS:
				return i18n.t('common.globals.bank.account_type.savings');

			case Constants.BANK.ACCOUNT_TYPE.CURRENT:
				return i18n.t('common.globals.bank.account_type.current');

			default:
				return i18n.t('common.globals.bank.account_type.undefined');
		}
	}

	getPropertyTypeName = (type: number) => {
		switch (type) {
			case Constants.PROPERTY.TYPE.HOUSE.ID:
				return Constants.PROPERTY.TYPE.HOUSE.LABEL;

			case Constants.PROPERTY.TYPE.DEPARTMENT.ID:
				return Constants.PROPERTY.TYPE.DEPARTMENT.LABEL;

			case Constants.PROPERTY.TYPE.LOCAL.ID:
				return Constants.PROPERTY.TYPE.LOCAL.LABEL;

			default:
				return i18n.t('common.globals.bank.account_type.undefined');
		}
	}

	getServiceStatusName = (type: number) => {
		switch (type) {
			case Constants.SERVICES.STATUS.PENDING.ID:
				return Constants.SERVICES.STATUS.PENDING.LABEL;

			case Constants.SERVICES.STATUS.FINISHED.ID:
				return Constants.SERVICES.STATUS.FINISHED.LABEL;

			case Constants.SERVICES.STATUS.DENIED.ID:
				return Constants.SERVICES.STATUS.DENIED.LABEL;

			case Constants.SERVICES.STATUS.INIT.ID:
				return Constants.SERVICES.STATUS.INIT.LABEL;

			case Constants.SERVICES.STATUS.INITIATED.ID:
				return Constants.SERVICES.STATUS.INITIATED.LABEL;

			case Constants.SERVICES.STATUS.ACTIVE.ID:
				return Constants.SERVICES.STATUS.ACTIVE.LABEL;

			case Constants.SERVICES.STATUS.CANCELLED.ID:
				return Constants.SERVICES.STATUS.CANCELLED.LABEL;

			case Constants.SERVICES.STATUS.QUOTE_SENDED.ID:
				return Constants.SERVICES.STATUS.QUOTE_SENDED.LABEL;

			case Constants.SERVICES.STATUS.PENDING_ASSIGNING.ID:
				return Constants.SERVICES.STATUS.PENDING_ASSIGNING.LABEL;

			default:
				return i18n.t('common.globals.bank.account_type.undefined');
		}
	}

	getLevelName = (level: number) => {
		switch (level) {
			case Constants.USER.LEVEL.ADMIN:
				return 'common.globals.superadmin';

			case Constants.USER.LEVEL.OWNER:
				return 'common.globals.owner';

			case Constants.USER.LEVEL.RENTER:
				return 'common.globals.renter';

			case Constants.USER.LEVEL.PROPERTY_ADMIN:
				return 'common.globals.property_admin';

			case Constants.USER.LEVEL.CONTRACTOR:
				return 'common.globals.contractor';

			case Constants.USER.LEVEL.TECH:
				return 'common.globals.tech';

			default:
				return 'common.globals.undefined';
		}
	}

	fromPhotos(url: string) {
		return process.env.REACT_APP_BASE_STORAGE + url;
	}

	fromFiles(url: string) {
		return process.env.REACT_APP_BASE_STORAGE + url;
	}
}

export default new Globals();