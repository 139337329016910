import { combineReducers } from 'redux';
import user from './user';
import header from './header';
import language from './language';

export const reducer = combineReducers({
    user,
    header,
    language
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;