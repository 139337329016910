import { axios, Error, Globals } from 'utils';
import { AxiosError, AxiosResponse } from 'axios';
import { store } from 'store';

class Api {
	
	createResponse = (uri: string, params: any = {}) => {
		return new Promise((resolve,reject) => {
			params.__lang = store.getState().language.lang;
			if (!params.withoutLoading) {
				Globals.setLoading();
			}
			axios[params.hasFile ? 'upload' : 'post'](uri,params)
				.then((res: AxiosResponse) => {
					resolve(res.data);
				})
				.catch((err: AxiosError) => {
					Error.default(err);
					reject(err);
				})
				.finally(Globals.quitLoading);
		});
	}
}

export default new Api();