import { Api } from 'utils';

class AuthService {
	
	static login = async (params = {}) => Api.createResponse('auth/login',params);
	static reset = async (params = {}) => Api.createResponse('auth/reset',params);
	static checkCode = async (params = {}) => Api.createResponse('auth/check-code',params);

	static activate = async (body = {}) => await Api.createResponse('auth/verify', body);

	static recoverEmail = async (body = {}) => await Api.createResponse('auth/recover', body);
	static recoverCode = async (body = {}) => await Api.createResponse('auth/check-code', body);
	static recoverPassword = async (body = {}) => await Api.createResponse('auth/reset', body);
	
}

export default AuthService;