import React from "react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import Home from "screens/home"
// Auth
import RecoverPassword from "screens/auth/recover/RecoverPassword"

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  return (
    <Switch>
      <Route exact path="/" component={ Home } />
      <Route exact path="/recover/:code" component={ RecoverPassword } />
      <Redirect to="/" />
    </Switch>
  );
}

const App = () => (
  <Router>
    <Container />
  </Router>
);

export default App;
