const INITIAL_STATE = {
	onBack: null,
	title: ''
}

const header = (state = INITIAL_STATE, action: any) => {
	switch(action.type) {
		case 'SET_HEADER':
			return action.payload;

		default: 
			return state;
	}
}

export default header;